/* Styles for the absolute positioned search inputs */
.search-container {
    position: relative;
    display: inline-block;
}

.search-input {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 200px;
}

/* Pagination.css */

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    border: 1px solid #ccc;
    border-radius: 50px;
    padding: 5px 10px;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.pagination li.active a {
    background-color: #4fd1c5;
    color: white;
    border: 1px solid #4fd1c5;
}

.pagination li.disabled a {
    color: #ccc;
    cursor: not-allowed;
}

.custom-sweetalert div {
    color: #4fd1c5;
    font-family: 'Arial', sans-serif; /* Cambia 'Arial' al tipo de letra que prefieras */
    font-size: 24px; /* Cambia el tamaño de la letra según tus necesidades */
}
  
.custom-sweetalert{
    background: rgba(255, 255, 255, 0.8); /* Fondo blanco con 80% de opacidad */
    padding: 20px;
    border-radius: 10px; /* Bordes redondeados */
  }

.background-container-inicial {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/img/background_photo_1.jpg') no-repeat center center fixed; /* Reemplaza con la ruta a tu imagen de fondo */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que esté por encima de otros elementos */
  }

  .custom-button-inicial {
    background-color: #4fd1c5; /* Cambia el color de fondo */
    color: white; /* Cambia el color del texto */
    padding: 10px 20px; /* Ajusta el relleno */
    border: none; /* Elimina el borde */
    border-radius: 5px; /* Bordes redondeados */
    font-size: 16px; /* Ajusta el tamaño de la fuente */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
  }
  
  .custom-button-inicial:hover {
    background-color: #4fd1c5; /* Cambia el color de fondo al pasar el ratón */
  }

/* Responsive styles */
@media (max-width: 768px) {
    .pagination {
        flex-wrap: wrap;
    }
    .pagination li {
        margin: 3px;
    }
    .pagination li a {
        padding: 4px 8px;
    }
}

@media (max-width: 480px) {
    .pagination li {
        margin: 2px;
    }
    .pagination li a {
        padding: 3px 6px;
        font-size: 12px;
    }
}


  